import PageHeader from "../../../../compoenents/page-header/page-header.component";

const EditBarber = () => {
    return (
        <div className="container">
            <PageHeader title="Edit Barber" />
        </div>
    )
}

export default EditBarber;